// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These 
// styles are for the entire app and not just one component. Additionally, this 
// file can hold Sass mixins, functions, and placeholder classes to be imported 
// and used throughout the application.

/* montserrat-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* raleway-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-600italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/raleway-v18-latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#Raleway') format('svg'); /* Legacy iOS */
}

html {
  --ion-default-font: Raleway !important;
}

.icon-large {
  width: 150px;
  height: 150px;
}

.centered {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;
  min-width: 100%;
  position: relative;
}

ion-header {
  background-color: #fff;
}

.ion-bg-color-primary {
  background-color: #19868b;
}

.logo {
  display: block;
  max-width: 150px;
  margin: 20px auto;
}

.corner__logo {
  width: 45px;
  height: 45px;
}

.page-content-center {
  max-width: 600px;
  margin: 0 auto;
  height: 100%;
}

.footer-content-center {
  max-width: 600px;
  margin: 0 auto;
}

.header-content-center {
  max-width: 600px;
  margin: 0 auto;
}

ion-item {
  --min-height: 30px;
  --inner-border-width: 0;
  --padding-start: 0px;
}

ion-input[type=text],
ion-input[type=password],
ion-input[type=email],
ion-select {
  --padding-start: 10px !important;
  --padding-end: 10px !important;
  border: solid 2px #aaa;
  border-radius: 10px;
  width: 100%;

  &.input--uppercase {
    text-transform: uppercase;

    input::-webkit-input-placeholder {
      text-transform: initial;
    }

    input:-moz-placeholder {
      text-transform: initial;
    }

    input::-moz-placeholder {
      text-transform: initial;
    }

    input:-ms-input-placeholder {
      text-transform: initial;
    }

    input::placeholder {
      text-transform: initial;
    }
  }

  &:active,
  &:focus,
  &:focus-within {
    border-color: #19868b;
    background-color: #f2f2f2;
  }
}

form {
  width: 100%;
}

.form-group {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  text-align: center;
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}

.subtitle {
  text-align: center;
  font-size: 14px;
  margin-bottom: 35px;
}

.background {
  background-image: url("/assets/backgrounds/login.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.content-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
}

ion-back-button {
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 30px;
}

ion-button.ion-color-primary {
  --color: #fff;
  --color-focused: #fff;
  --color-hover: #fff;
  --color-activated: #19868b;
  --background: #67283d;
  --background-hover: #19868b;
  --background-focused: #19868b;
  --background-activated: #fff;
  font-weight: bold;
}

ion-button.ion-color-secondary {
  --color: #fff;
  --color-focused: #fff;
  --color-hover: #fff;
  --color-activated: #67283d;
  --background: #19868b;
  --background-hover: #67283d;
  --background-focused: #67283d;
  --background-activated: #fff;
  font-weight: bold;
}

ion-toolbar {
  --border-width: 0 !important;
}

/*.back-button {
  &--brown {
    color: #661834;
  }
}

ion-header:after {
  display: none;
}

ion-content {
  --background: #fff;
  --color: #000;
}

.error {
  color: rgb(228, 83, 83);
}

.success {
  color: rgb(81, 146, 64);
}

.title,
.subtitle {
  text-align: center;
  font-size: 15px;
  margin: 0px 0 35px;
  white-space: pre-line;
}

.subtitle {
  margin: -20px 0 35px;
}

.title {
  font-weight: bold;
  font-size: 20px;
}

ion-header .title {
  margin: 20px 0;
}

ion-header .subtitle {
  margin: -15px 0 20px;
}

.fill-white {
  fill: #fff;
}

.form {
  width: 100%;
}

.input {
  border: solid 2px #aaa;
  border-radius: 10px;

  --padding-end: 10px;
  --padding-start: 10px;
  margin-bottom: 15px;
  width: 100%;

  &.input--uppercase {
    text-transform: uppercase;


    input::-webkit-input-placeholder {
      text-transform: initial;
    }

    input:-moz-placeholder {
      text-transform: initial;
    }

    input::-moz-placeholder {
      text-transform: initial;
    }

    input:-ms-input-placeholder {
      text-transform: initial;
    }

    input::placeholder {
      text-transform: initial;
    }
  }

  &:active,
  &:focus,
  &:focus-within {
    border-color: #19868b;
    background-color: #f2f2f2;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ion-footer {
  ion-button {
    margin: 0px;
  }
}
*/

//
//.require-rotate {
//  display: none;
//  position: fixed;
//  z-index: 999999;
//  top: 0px;
//  left: 0px;
//  right: 0px;
//  bottom: 0px;
//  background: #000 url('/assets/backgrounds/turn-device.png') center center / cover no-repeat;
//}
//
//@media (max-height: 599px) and (orientation: landscape) {
//  .require-rotate {
//    display: block;
//  }
//  .require-rotate.video {
//    display: none;
//  }
//}
//
//body {
//  --themeWhite: #fff;
//  --themeColor1: #19868B;
//  --themeColor2: #f6c12a;
//  --themeColor3: #f7a37f;
//  --themeColor4: #f2dcbc;
//  --themeColor5: #4abcc1;
//}
//
//ion-content {
//  --background: transparent;
//}
//
//.theme-custom,
//.theme-custom-nologo,
//.theme-top,
//.theme-bottom,
//.theme-video,
//.theme-clean,
//.theme-default {
//  width: 100%;
//  height: 100%;
//}
//
//.theme-custom,
//.theme-custom-nologo {
//  background-color: var(--themeColor1);
//  --themeWhite: #fff;
//  --themeWhiteRgb: 255,255,255;
//
//  @media (min-width: 768px) and (min-height: 480px) {
//    --themeWhite: #000;
//    --themeWhiteRgb: 0,0,0;
//  }
//}
//
//.top-logo {
//  position: absolute;
//  top: 20px;
//  right: 25px;
//  width: 35px;
//  height: 35px;
//  background: transparent url('/assets/logos/corner.png') center center / cover no-repeat;
//  z-index: 999;
//
//  @media (min-width: 768px) and (min-height: 768px) {
//    display: none;
//  }
//}
//
//.theme-custom {
//  .top-logo {
//    background: transparent url('/assets/logos/corner.png') center center / cover no-repeat;
//  }
//}
//
//
//.back-button {
//  position: absolute;
//  top: 5px;
//  left: 5px;
//  --color: #67283d;
//  --color-focused: #19868b;
//  --color-activated: #19868b;
//}
//
//.back-button.light {
//  --color: var(--themeWhite);
//  --color-focused: #19868b;
//  --color-activated: #19868b;
//}
//
//.title,
//.subtitle {
//  text-align: center;
//  width: 90%;
//  font-size: 15px;
//  margin: 10px auto;
//}
//
//.title {
//  font-weight: bold;
//  font-size: 20px;
//  margin-top: 50px;
//
//  &.close {
//    margin-bottom: 3px;
//  }
//
//  &.absolute {
//    position: absolute;
//    top: 50px;
//    left: 50%;
//    width: 60%;
//    margin-top: 0px;
//    transform: translateX(-50%);
//    text-align: center;
//  }
//}
//
//.subtitle {
//  margin-bottom: 35px;
//
//  &.close {
//    margin-top: 3px;
//  }
//
//  &.absolute {
//    position: absolute;
//    top: 85px;
//    left: 50%;
//    width: 80%;
//    transform: translateX(-50%);
//    text-align: center;
//  }
//}
//
//.input {
//  border: solid 2px #aaa;
//  border-radius: 10px;
//  --padding-end: 10px;
//  --padding-start: 10px;
//  margin-bottom: 15px;
//
//  &:active,
//  &:focus,
//  &:focus-within {
//    border-color: #19868b;
//    background-color: #f2f2f2;
//  }
//}
//
//.error {
//  text-align: center;
//  color: rgb(228, 83, 83);
//}
//
//.success {
//  text-align: center;
//  color: #5fb78d;
//}
//
//.submit-round {
//  position: absolute;
//  bottom: 0px;
//  left: 50%;
//  transform: translate(-50%, -50%);
//  color: var(--themeWhite);
//  font-size: 85px;
//  cursor: pointer;
//  z-index: 999;
//}
//
//.submit {
//  position: absolute;
//  bottom: 30px;
//  left: 30px;
//  right: 30px;
//  font-weight: bold;
//  --color: #fff;
//  --color-focused: #fff;
//  --color-hover: #fff;
//  --color-activated: #19868b;
//  --background: #67283d;
//  --background-hover: #19868b;
//  --background-focused: #19868b;
//  --background-activated: #fff;
//}
//
//.form {
//  position: absolute;
//  top: 45%;
//  left: 30px;
//  right: 30px;
//  transform: translateY(-50%);
//}
//
//.form-top {
//  margin: 30px 30px 0 30px;
//}
//
//.theme-default {
//  background-image: url('/assets/backgrounds/shutterstock_486846196.jpg');
//  filter: blur(4px);
//}
//
//.centered {
//  display: flex;
//
//  @media (min-width: 768px) and (min-height: 480px) {
//    position: absolute;
//    top: 70px;
//    bottom: 70px;
//    left: 50%;
//    transform: translateX(-50%);
//    height: auto;
//    min-width: 350px;
//  }
//
//  @media (min-width: 768px) and (min-height: 768px) {
//    top: 140px;
//    bottom: 70px;
//  }
//
//  .desktop-tabs {
//    display: none;
//    width: 350px;
//    margin-left: 40px;
//
//    @media (min-width: 992px) and (min-height: 800px) {
//      display: block;
//    }
//
//    &.hide {
//      display: none;
//    }
//
//    .buttons {
//      background-color: #fff;
//      padding: 10px 20px;
//
//      ion-row {
//        color: #000;
//        align-items: center;
//        cursor: pointer;
//
//        &.disabled {
//          color: #aaa;
//        }
//
//        ion-icon {
//          font-size: 40px;
//          margin-right: 20px;
//        }
//
//        &.active {
//          color: var(--themeColor1);
//        }
//      }
//    }
//  }
//
//  @media (min-width: 992px) and (min-height: 800px) {
//    .app-router {
//      position: relative;
//      min-width: 350px;
//      top: 0px;
//      left: 0px;
//      right: 0px;
//      bottom: 0px;
//    }
//  }
//}
//
//.theme-video {
//  @media (min-width: 768px) and (min-height: 480px) {
//    .centered {
//      position: absolute;
//      top: 50%;
//      left: 50%;
//      bottom: auto;
//      right: auto;
//      transform: translate(-50%, -50%);
//      height: 336px;
//      width: 600px;
//    }
//  }
//
//  @media (min-width: 992px) and (min-height: 800px) {
//    .centered {
//      height: 416px;
//      width: 740px;
//    }
//
//    .app-router {
//      position: static;
//      width: 600px;
//    }
//  }
//}
//
//ion-tab-bar {
//  @media (min-width: 992px) and (min-height: 800px) {
//    display: none;
//  }
//}
//
//.page {
//  @media (min-width: 768px) and (min-height: 480px) {
//    background: #fff;
//  }
//}
//
//.theme-clean {
//  .top-logo {
//    background: transparent url('/assets/logos/corner.png') center center / cover no-repeat;
//  }
//
//  .page {
//    background: #fff;
//  }
//}
//
//.theme-top,
//.theme-top-nologo {
//  background-color: var(--themeColor1);
//
//  .page {
//    background: #fff;
//  }
//
//  .top-logo {
//    background: transparent url('/assets/logos/full.png') center center / cover no-repeat;
//    width: 164px;
//    height: 50px;
//    right: 50%;
//    transform: translateX(50%);
//    top: 80px;
//  }
//}
//
//.theme-video,
//.theme-video-lock {
//  background-color: #000;
//
//  .page {
//    background: #000;
//  }
//
//  .top-logo {
//    display: none;
//  }
//}
//
//.theme-nologo,
//.theme-custom-nologo,
//.theme-top-nologo {
//  .top-logo {
//    display: none;
//  }
//}
//
//.theme-bottom {
//  background-color: #fff;
//
//  .top-logo {
//    background: transparent url('/assets/logos/full.png') center center / cover no-repeat;
//  }
//
//  .page {
//    background: #fff;
//  }
//}
//
//.text-center {
//  text-align: center;
//}
//
//.loading {
//  position: absolute;
//  top: 0px;
//  left: 0px;
//  right: 0px;
//  bottom: 0px;
//  background: #fff;
//  z-index: 99999;
//
//  .logo {
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%, -100%);
//    width: 130px;
//    height: 42px;
//  }
//}
//
//.noselect {
//  -webkit-touch-callout: none; /* iOS Safari */
//    -webkit-user-select: none; /* Safari */
//     -khtml-user-select: none; /* Konqueror HTML */
//       -moz-user-select: none; /* Old versions of Firefox */
//        -ms-user-select: none; /* Internet Explorer/Edge */
//            user-select: none; /* Non-prefixed version, currently
//                                  supported by Chrome, Opera and Firefox */
//}
//
//.modal-backdrop {
//  position: absolute;
//  top: 0px;
//  left: 0px;
//  width: 100%;
//  height: 100%;
//  z-index: 999;
//  overflow: auto;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//
//  .modal {
//    position: relative;
//    border-radius: 10px;
//    background-color: #fff;
//    border: solid 2px var(--themeColor1);
//    padding: 20px;
//    width: 90%;
//    overflow: auto;
//    max-height: 80%;
//
//    &.button {
//      padding-bottom: 77px !important;
//    }
//
//    .close {
//      position: absolute;
//      top: 0px;
//      right: 0px;
//      font-size: 15px;
//      --color: var(--themeColor1);
//    }
//
//    h3 {
//      font-size: 16px;
//      font-weight: bold;
//    }
//
//    p {
//      font-size: 14px;
//    }
//
//    .footer-button {
//      position: absolute;
//      bottom: 0px;
//      left: 0px;
//      right: 0px;
//      border-top: solid 1px var(--themeColor1);
//      height: 55px;
//      color: #000;
//    }
//  }
//}
//
//.uppercase {
//  text-transform: uppercase;
//}
//
//.header {
//  background-color: #fff;
//  position: fixed;
//  top: 0px;
//  left: 0px;
//  right: 0px;
//  height: 100px;
//  display: none;
//  width: 100%;
//  justify-content: center;
//  align-items: center;
//  border-bottom: solid 1px #eee;
//
//  @media (min-width: 768px) and (min-height: 768px) {
//    display: flex;
//  }
//
//  .header-logo {
//    width: 160px;
//    display: block;
//    height: auto;
//  }
//}
//
//.fill-white {
//  fill: #fff;
//
//  @media (min-width: 768px) and (min-height: 480px) {
//    fill: var(--themeColor1);
//    opacity: 1;
//  }
//}
//
//.fill-grey {
//  fill: #ccc;
//
//  @media (min-width: 768px) and (min-height: 480px) {
//    fill: var(--themeColor1);
//    opacity: 0.3;
//  }
//}
//
//.fill-color1 {
//  fill: var(--themeColor1);
//}
//
//.fill-color2 {
//  fill: var(--themeColor2);
//}
//
//.theme-video .desktop-tabs {
//  display: none;
//}
//
//a {
//  color: var(--ion-color-primary);
//
//  &:focus {
//    color: #19868b;
//  }
//}
//
//.content a {
//    color: var(--themeWhite) !important;
//    text-decoration: underline;
//}