.etiquette__popup {
  .popover-content {
    min-width: 260px;
    max-width: 400px;
    width: 80%;

    .title {
      font-size: 15px;
      margin: 20px 0 10px;
    }

    .etiquette__popup__content {
      max-height: 36vh;
      overflow: auto;
      font-size: 13px;
      margin-bottom: 5px;
      margin-top: 15px;

      p {
        &:first-child {
          margin-top: 0px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }

      ol {
        padding-left: 20px;
      }
    }

    .etiquette__popup__button {
      margin-top: 20px;
    }
  }

  .etiquette__popup__radio {
    font-size: 13px;
  }

  .form {
    text-align: center;
  }

  .etiquette__popup__close {
    position: absolute;
    top: 0px;
    right: 10px;

    ion-button {
      --color: #000;
      --padding-start: 0px;
      --padding-end: 0px;
      --padding-top: 0px;
      --padding-bottom: 0px;
      height: 1em;
    }
  }

  .etiquette__popup__radio {
    opacity: 1;
  }
}
