.certificate__popup {
  .popover-content {
    min-width: 260px;
    max-width: 400px;
    width: 80%;

    .title {
      font-size: 15px;
      margin: 20px 0 10px;
    }

    .subtitle {
      font-size: 12px;
      color: #67283d;
      font-weight: bold;
      margin: 0 0 10px;
    }

    .certificate__popup__content {
      max-height: 40vh;
      overflow: auto;
      font-size: 13px;
      margin-bottom: 5px;
      margin-top: 15px;
      text-align: center;

      p {
        &:first-child {
          margin-top: 0px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .certificate__popup__image {
    max-width: 100px;
    margin: 20px auto 10px;
  }

  .certificate__popup__radio {
    font-size: 13px;
  }

  .form {
    text-align: center;
  }

  .certificate__popup__close {
    position: absolute;
    top: 10px;
    right: 10px;

    ion-button {
      --color: #000;
      --padding-start: 0px;
      --padding-end: 0px;
      --padding-top: 0px;
      --padding-bottom: 0px;
      height: 1em;
    }
  }
}
